// extracted by mini-css-extract-plugin
export var body = "Dropdown__body__W0RbM";
export var column = "Dropdown__column__eNy6y";
export var flip = "Dropdown__flip__saPbR";
export var icon = "Dropdown__icon__oQsjy";
export var marginLeft = "Dropdown__marginLeft__fqzYd";
export var marginRight = "Dropdown__marginRight__HEzbd";
export var row = "Dropdown__row__DOLLe";
export var sizeLg = "Dropdown__sizeLg__TCMid";
export var sizeMd = "Dropdown__sizeMd__aXGqd";
export var sizeSm = "Dropdown__sizeSm__T6AMy";
export var sizeXl = "Dropdown__sizeXl__Rxl1z";
export var sizeXs = "Dropdown__sizeXs__YQXY1";
export var sizeXxl = "Dropdown__sizeXxl__wsMGL";
export var sizeXxs = "Dropdown__sizeXxs__vTXFe";
export var sizeXxxl = "Dropdown__sizeXxxl__jbWbi";
export var sizeXxxs = "Dropdown__sizeXxxs__N57x6";
export var sizeXxxxl = "Dropdown__sizeXxxxl__ixwgd";
export var sizeXxxxxl = "Dropdown__sizeXxxxxl__oH2GV";
export var trigger = "Dropdown__trigger__nuZWP";
export var wrapper = "Dropdown__wrapper__pAJsk";